// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_q6 d_bD";
export var storyRowWrapper = "r_hx d_hx d_bK";
export var storyLeftWrapper = "r_q7 d_bz d_bP";
export var storyWrapperFull = "r_q8 d_cD";
export var storyWrapperFullLeft = "r_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "r_mv d_hy";
export var storyLeftWrapperCenter = "r_q9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "r_rb d_hF";
export var storyHeader = "r_rc d_hD d_w d_cs";
export var storyHeaderCenter = "r_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "r_hB d_hB d_by d_dw";
export var storyBtnWrapper = "r_rd d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "r_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "r_rf d_fg d_Z";
export var imageWrapperFull = "r_rg d_w d_H d_bf d_Z";
export var SubtitleSmall = "r_qd v_qd v_rm v_ry";
export var SubtitleNormal = "r_qf v_qf v_rm v_rz";
export var SubtitleLarge = "r_qg v_qg v_rm v_rB";
export var textLeft = "r_dv";
export var textCenter = "r_dw";
export var textRight = "r_dx";